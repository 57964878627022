import {
  CloseCircleOutlined,
  CrownOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  message,
  Modal,
  Row,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as ChartIcon } from "assets/images/chart-icon.svg";
import { reviewBusinessApplication } from "services/SauceService";
import { ReactComponent as TicketIcon } from "assets/images/ticket-icon.svg";
import { ReactComponent as TimerIcon } from "assets/images/timer-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";
import NotFoundImage from "assets/images/not-found.jpeg";
import "../home/Home.scss";
import ModalCollabTimeSlot from "./ModalCollabTimeSlot";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RescheduleRequest from "components/reschedule-request/RescheduleRequest";
import dayjs from "dayjs";
import { Subscription } from "interfaces/subscription";
import { useSelector } from "react-redux";
import { InfluencerType } from "enums/InfluencerType";
const { Countdown } = Statistic;

interface InfluencerCardProps {
  influencer: any;
  onInfluencerClick: (influencer?: any) => void;
  hideLocation?: boolean;
}

export const formatNumberKMBT = (number: number) => {
  if (!number && number !== 0) return "-";
  if (number > 999 && number <= 999999) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number > 999999 && number <= 999999999) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number > 999999999 && number <= 999999999999) {
    return (number / 1000000000).toFixed(1) + "B";
  } else if (number > 999999999999) {
    return (number / 1000000000000).toFixed(1) + "T";
  } else {
    return number;
  }
};

export const ImgWithErrorHandling: React.FC<any> = ({ src, ...rest }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
    return true;
  };

  return imgError ? (
    <img src={NotFoundImage} {...rest} alt="" />
  ) : (
    <img src={src} onError={handleError} {...rest} alt="" />
  );
};

const InfluencerCard: React.FC<InfluencerCardProps> = (props) => {
  const { influencer, hideLocation, onInfluencerClick } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [openModalTimeSlot, setOpenModalTimeSlot] = useState<boolean>(false);
  const now = dayjs();
  const endDate = dayjs.utc(influencer?.dueDateToReview || null);
  const { subscription }: { subscription: Subscription } = useSelector(
    (state: any) => state.business
  );

  const influencerType = influencer?.isPremium
    ? InfluencerType.premium
    : InfluencerType.basic;

  const spotsInformation = subscription?.spotsUsageByType?.find(
    (spot) => spot.type === influencerType
  );

  const limitReached = (spotsInformation?.amountRemaining || 0) < 1;

  const onClickReject = async (evt: any) => {
    evt.stopPropagation();

    Modal.confirm({
      content: "Are you sure you want to reject this influencer?",
      icon: "",
      onOk: async () => {
        await reviewBusinessApplication({
          bookingId: influencer?.bookingId,
          collabId: influencer?.collabId,
          status: "reject",
        });

        navigate(location.pathname, { replace: true });
      },
      okButtonProps: {
        type: "primary",
      },
    });
  };

  const bookDate = influencer?.formatDate
    ? dayjs.utc(influencer?.formatDate.date || "").format("DD MMM YYYY")
    : influencer?.requestDates && influencer?.requestDates.length > 0
    ? dayjs.utc(influencer?.requestDates[0]?.date || "").format("DD MMM YYYY")
    : "";

  const bookTime = influencer?.formatDate
    ? influencer?.formatDate?.hour
    : influencer?.requestDates && influencer?.requestDates.length > 0
    ? influencer?.requestDates[0]?.hour
    : "";

  const onClickAccept = (evt: any) => {
    if (influencer?.venue?.ghostKitchen) {
      Modal.confirm({
        content: (
          <>
            <Row>
              <span>
                <b>Delivery address:</b> {influencer?.deliveryAddress}
              </span>
            </Row>
            <Row>
              <span>
                <b>Date:</b> {`${bookDate} - ${bookTime}`}
              </span>
            </Row>
          </>
        ),
        icon: "",
        title: "Are you sure you want Accept this collab?",
        onOk: async () => {
          try {
            reviewBusinessApplication({
              bookingId: influencer?.bookingId,
              collabId: influencer?.collabId,
              slotTimeId: influencer?.requestDates[0]?._id,
              status: "approve",
            });

            navigate(location.pathname, { replace: true });
          } catch {
            message.error("Error accepting collab");
          }
        },
        okButtonProps: {
          type: "primary",
        },
      });
    } else {
      setOpenModalTimeSlot(true);
    }
    evt.stopPropagation();
  };

  const onClickCard = (evt: any) => {
    evt.stopPropagation();
    if (openModalTimeSlot) return;
    onInfluencerClick(influencer);
  };

  const classColorByEngagementRate = (engagementRate: number) => {
    if (engagementRate < 1) return "low";
    if (engagementRate < 2) return "medium";
    return "high";
  };

  // const onClickReschedule = async () => {};

  // function formatDueDate(): string {
  //   const now = dayjs();
  //   const endDate = dayjs.utc(influencer?.dueDateToReview);

  //   if (!endDate.isValid() || endDate.isBefore(now)) {
  //     return "Expiring soon";
  //   }

  //   const diff = endDate.diff(now);

  //   const diffDuration = dayjs.duration(diff);

  //   const hours = Math.floor(diffDuration.asHours())
  //     .toString()
  //     .padStart(2, "0");
  //   const minutes = diffDuration.minutes().toString().padStart(2, "0");

  //   return `${hours}h ${minutes}m`;
  // }
  const canRescheduleAccepted =
    influencer?.tabStatus === "accepted" &&
    dayjs(influencer?.applicationDate?.date).isAfter(now.add(48, "hour"));

  return (
    <div key={influencer?.bookingId} className="influencer-card">
      <ModalCollabTimeSlot
        open={openModalTimeSlot}
        onCancel={() => setOpenModalTimeSlot(false)}
        requestDates={influencer.requestDates}
        influencer={influencer}
      />
      {influencer?.venue?.name && !hideLocation && (
        <div className="venue">
          <LocationIcon /> {influencer?.venue?.name}
          {" - "}
          {influencer?.venue?.addressDisplay}
        </div>
      )}
      {influencer?.tabStatus === "accepted" && (
        <div className="venue display">
          <CalendarIcon />{" "}
          {dayjs
            .utc(influencer?.applicationDate?.date || null)
            .format("MMM DD")}
          {" At "} {influencer?.applicationDate?.hour}
        </div>
      )}
      <div className="content">
        <div onClick={onClickCard} style={{ cursor: "pointer" }}>
          <Row>
            {/* <Col xs={8}> */}
            <div className="social">
              {influencer?.instagramFollowersCount && (
                <>
                  <InstagramIcon />
                  {formatNumberKMBT(influencer?.instagramFollowersCount)}
                </>
              )}
            </div>
            {/* </Col> */}
            <div className="home-applications">
              <div className="avatar">
                <Badge
                  count={
                    influencer?.isPremium ? (
                      <CrownOutlined
                        style={{
                          background: "#faad14",
                          color: "#1D2023",
                          borderRadius: "50%",
                          padding: 4,
                          fontSize: 12,
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  color="#faad14"
                  offset={[-15, 10]}
                >
                  <Avatar
                    src={influencer?.avatarUrl}
                    size={60}
                    className={`${influencer?.isPremium ? "premium" : ""}`}
                  />
                </Badge>

                {influencer?.collabMatchRate && (
                  <span
                    className={`tag ${influencer?.isPremium ? "premium" : ""}`}
                  >
                    {influencer?.collabMatchRate}% match
                  </span>
                )}
              </div>
            </div>
            {/* <Col xs={8}> */}
            <div className="social">
              {influencer?.tiktokFollowersCount && (
                <>
                  <TikTokIcon />
                  {formatNumberKMBT(influencer?.tiktokFollowersCount)}
                </>
              )}
            </div>
            {/* </Col> */}
          </Row>
          <Row justify="center">
            <Col xs={24}>
              <span className="name">
                {influencer?.username}{" "}
                <RightOutlined style={{ fontSize: 12, marginTop: 2 }} />
              </span>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={8}
            style={{ margin: "16px 0", height: 110, alignItems: "center" }}
          >
            {influencer?.top3PostsCoverImgUrl?.length ? (
              influencer?.top3PostsCoverImgUrl?.map(
                (post: any, index: number) => (
                  <Col xs={8} key={index}>
                    <ImgWithErrorHandling
                      src={post}
                      alt=""
                      className="image-post"
                    />
                  </Col>
                )
              )
            ) : (
              <Typography.Text
                style={{ color: "#999999", textAlign: "center" }}
              >
                Influencer profile is being enriched
              </Typography.Text>
            )}
          </Row>
          <Row>
            <Col xs={8}>
              <div className="social">
                <span className="attributes">
                  Buy Intent
                  <Tooltip
                    title="Purchase intent is counted when people in the comments express interest in visiting the venue or trying the product featured in the video."
                    className="tooltip-info"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
                {influencer?.dinnerIntentByPosts || "-"}/post
              </div>
            </Col>
            <Col xs={8}>
              <div className="social">
                <span className="attributes">
                  Eng
                  <Tooltip
                    title="Engagement is counted for actions such as likes, shares, and comments."
                    className="tooltip-info"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
                <span>
                  <ChartIcon
                    className={classColorByEngagementRate(
                      influencer?.engagementRate
                    )}
                  />
                  {influencer?.engagementRate || "-"}%
                </span>
              </div>
            </Col>
            <Col xs={8}>
              <div className="social">
                <span className="attributes">
                  Rating
                  <Tooltip
                    title="Influencer ratings are built over time and are based on their performance in previous collaborations. The rating decreases if the influencer cancels at the last minute, has no-shows, or fails to deliver content on time."
                    className="tooltip-info"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
                {influencer?.commitmentRate || "-"}%
              </div>
            </Col>
          </Row>
        </div>
        {influencer?.tabStatus === "accepted" && (
          <>
            <Row
              style={{
                gap: 8,
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
                fontSize: 13,
              }}
            >
              <TicketIcon /> {influencer?.securityCode} {" ∘ "}
              {influencer?.foodCompAmount}
              {" credit "}
              {influencer?.guestAllowed && "(guest allowed)"}
            </Row>
            {influencer?.venue?.ghostKitchen && (
              <>
                <Row style={{ marginTop: 6 }}>
                  <span>
                    <b>Delivery address:</b> {influencer?.deliveryAddress}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Date:</b> {`${bookDate} - ${bookTime}`}
                  </span>
                </Row>
              </>
            )}
            {canRescheduleAccepted && (
              <Row justify="center" style={{ marginTop: 16 }}>
                <RescheduleRequest influencer={influencer} />
              </Row>
            )}
          </>
        )}
        {influencer?.tabStatus === "pending" && (
          <>
            {influencer?.bookingStatus === 2 ? (
              <Row style={{ gap: 8, marginTop: 20 }} justify="center">
                <span>Pending influencer accept</span>
              </Row>
            ) : (
              <>
                <Row style={{ gap: 8, marginTop: 20 }}>
                  <Button
                    type="primary"
                    className="accept"
                    onClick={onClickAccept}
                  >
                    <span style={{ fontWeight: 500 }}>Accept</span>
                    {/* <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <TimerIcon /> {formatDueDate()}
                </span> */}
                    {!endDate.isValid() || endDate.isBefore(now) ? (
                      "Expiring soon"
                    ) : (
                      <Countdown
                        value={dayjs(influencer?.dueDateToReview)
                          .toDate()
                          .toISOString()}
                        format="HH:mm:ss"
                        prefix={<TimerIcon />}
                        className="countdown"
                      />
                    )}
                  </Button>
                  <Button
                    icon={<CloseCircleOutlined />}
                    onClick={onClickReject}
                    style={{ height: 50, width: 50 }}
                  />
                </Row>
                {!limitReached && (
                  <Row justify="center" style={{ marginTop: 16 }}>
                    <RescheduleRequest influencer={influencer} />
                  </Row>
                )}
                {influencer?.venue?.ghostKitchen && (
                  <>
                    <Row style={{ marginTop: 6 }}>
                      <span>
                        <b>Delivery address:</b> {influencer?.deliveryAddress}
                      </span>
                    </Row>
                    <Row>
                      <span>
                        <b>Date:</b> {`${bookDate} - ${bookTime}`}
                      </span>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}
        {influencer?.tabStatus === "expired" && !limitReached && (
          <Row justify="center" style={{ marginTop: 16 }}>
            <RescheduleRequest influencer={influencer} />
          </Row>
        )}
      </div>
    </div>
  );
};

export default InfluencerCard;
