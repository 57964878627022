import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./BusinessMessage.scss";

export const BusinessMessage = ({
  content,
  state,
  sender,
  _id,
}: Chat["messages"][number]) => {
  return (
    <div key={_id} className="business-message">
      <div>
        <Avatar src={sender.avatarUrl} />
      </div>
      <div className="business-message__content">{content}</div>
    </div>
  );
};
