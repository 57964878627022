import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./FoodieMessage.scss";

export const FoodieMessage = ({
  content,
  state,
  sender,
  _id,
}: Chat["messages"][number]) => {
  return (
    <div key={_id} className="foodie-message">
      <div>
        <Avatar src={sender.avatarUrl} />
      </div>
      <div className="foodie-message__content">
        {content}
        <div className="foodie-message__sender">@{sender.name}</div>
      </div>
      {state === "sending" && (
        <LoadingOutlined className="foodie-message__status-icon" />
      )}
      {state === "error" && (
        <WarningOutlined className="foodie-message__status-icon foodie-message__status-icon--error" />
      )}
    </div>
  );
};
