import { Checkbox } from "antd";
import { useState } from "react";

const CheckboxGroup: React.FC<any> = (props) => {
  const { options, allValue, allLabel, onChange } = props;
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [groupValue, setGroupValue] = useState<string[]>([]);

  const onChangeAllValue = (e: any) => {
    setAllSelected(e.target.checked);
    onChange([allValue]);
    setGroupValue([]);
  };

  const onChangeValues = (values: any) => {
    setAllSelected(false);
    onChange(values);
    setGroupValue(values);
  };

  return (
    <div>
      <Checkbox
        value={allValue}
        checked={allSelected}
        onChange={onChangeAllValue}
      >
        {allLabel}
      </Checkbox>
      <div style={{ maxHeight: 100, overflow: "auto" }}>
        <Checkbox.Group
          options={options}
          onChange={onChangeValues}
          value={groupValue}
          style={{ display: "flex", flexDirection: "column" }}
        />
      </div>
    </div>
  );
};

export default CheckboxGroup;
