import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  sendForgotPasswordEmail,
  sendForgotPasswordNewPassword,
} from "services/SauceService";

const ForgotPassword: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const email = Form.useWatch("email", form);

  const handleNext = async () => {
    const { email, verificationCode, password, confirmPassword } =
      form.getFieldsValue(true);
    if (currentStep === 0) {
      try {
        message.success("Verification code sent to your email");
        await sendForgotPasswordEmail({ email });
      } catch (error) {}
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 1) {
      if (password !== confirmPassword) {
        message.error("Passwords do not match");
        return;
      }
      try {
        await sendForgotPasswordNewPassword({
          email,
          password,
          verificationCode,
        });
        message.success("Password reset successfully");
        navigate("/login");
      } catch (error: any) {
        message.error(error?.message);
      }
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20vh",
        height: "100vh",
      }}
    >
      <div style={{ marginTop: 24 }}>
        <Form style={{ width: 300 }} onFinish={handleNext} form={form}>
          {currentStep === 0 && (
            <>
              <h2>Forgot password</h2>
              <Form.Item
                required
                name="email"
                rules={[{ type: "email", message: "Invalid email" }]}
              >
                <Input type="email" placeholder="Email" />
              </Form.Item>
              <Button
                type="primary"
                disabled={!email}
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Next
              </Button>
              <Button
                type="link"
                onClick={() => navigate(-1)}
                style={{ marginTop: 8, width: "100%" }}
              >
                Back
              </Button>
            </>
          )}
          {currentStep === 1 && (
            <>
              <Form.Item
                name="verificationCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your verification code!",
                  },
                ]}
              >
                <Input placeholder="Verification Code" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters",
                  },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>

              <Button
                type="primary"
                onClick={handleNext}
                style={{ width: "100%" }}
              >
                Reset Password
              </Button>
              <Button
                type="link"
                onClick={handlePrev}
                style={{ marginTop: 8, width: "100%" }}
              >
                Back
              </Button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
