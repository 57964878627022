import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./MustardMessage.scss";

export const MustardMessage = ({
  content,
  state,
  sender,
}: Chat["messages"][number]) => {
  return (
    <div className="mustard-message">
      <div>
        <Avatar src={sender.avatarUrl} />
      </div>
      <div className="mustard-message__content">
        {content}
        <div className="mustard-message__sender">{sender.name}</div>
      </div>
    </div>
  );
};
