import { Col } from "antd/es/grid";
import { Avatar, Row } from "antd";
import { Chat } from "enums/ChatType";
import { ArrowRightOutlined, CrownOutlined } from "@ant-design/icons";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";

import "./RowMessage.scss";

export const RowMessage = ({
  chat,
  onClick,
}: {
  chat: Chat;
  onClick: () => void;
}) => {
  const businessId = localStorage.getItem("businessId");
  const otherParticipants = chat.participants.filter(
    (participant) => participant.participantId !== businessId
  );

  const participant = otherParticipants[0];

  if (!participant) {
    return null;
  }

  const lastMessage = chat.messages[chat.messages.length - 1];

  return (
    <div className={`row-message ${chat.unreadCount === 0 ? "faded" : ""}`}>
      <Row className={`row-message__container`} onClick={onClick}>
        <Col className="row-message__participant-col">
          <div style={{ position: "relative" }}>
            <Avatar src={participant.avatarUrl} size={60} />
            {participant.isPremium && (
              <span
                className="diamond"
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CrownOutlined />
              </span>
            )}
          </div>
          <div className="row-message__participant-info">
            <div className="row-message__participant-name">
              @{participant.participantName}
            </div>

            <div className="row-message__context-info">
              <div className="row-message__context-item row-message__context-item--booking">
                <CalendarIcon className="icon" />
                {chat.context.booking?.bookDate
                  ? new Date(chat.context.booking.bookDate).toLocaleDateString()
                  : "-"}
                <ClockIcon className="icon" />
                {chat.context.booking?.bookHour
                  ? chat.context.booking.bookHour
                  : "-"}
              </div>
              <span className="row-message__context-item">
                <LocationIcon className="icon" />
                {chat.context.venue?.name ?? "-"}
              </span>
              <span className="row-message__venue-address">
                {chat.context.venue?.address ?? "-"}
              </span>
            </div>
          </div>
        </Col>
        <Col className="row-message__message-col">
          <div className="row-message__message-content">
            {lastMessage ? lastMessage.content : <i>No messages yet</i>}
          </div>
        </Col>
        <Col className="row-message__arrow-col">
          <ArrowRightOutlined />
        </Col>
      </Row>
    </div>
  );
};
