import { Col, Row, Segmented, Spin, Typography } from "antd";
import { useState } from "react";
import BusinessInfo from "./BusinessInfo";
import "./Settings.scss";
import Locations from "./Locations";
import Account from "./Account";
import Subscription from "./Subscription";
import { useNavigation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Domain from "./Domain";

enum SettingsTabs {
  BUSINESS_INFO = "business-info",
  LOCATIONS = "locations",
  DOMAIN = "domain",
  SUBSCRIPTION = "subscription",
  ACCOUNT = "account",
}

const Settings: React.FC = () => {
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [selectedTab, setSelectedTab] = useState<string>(
    SettingsTabs.BUSINESS_INFO
  );

  if (loading) return <Spin />;

  return (
    <div className="settings">
      <Typography.Title>Settings</Typography.Title>
      <Segmented
        options={[
          { label: "Business Info", value: "business-info" },
          { label: "Locations", value: "locations" },
          // { label: "Domain", value: "domain" },
          { label: "Subscription", value: "subscription" },
          { label: "Account", value: "account" },
        ]}
        value={selectedTab}
        onChange={(key) => setSelectedTab(key)}
        style={{
          marginBottom: isMobile ? 12 : 32,
          width: isMobile ? "100%" : "unset",
        }}
        className="segmented-mobile"
      />
      <Row style={{ marginTop: 40 }}>
        <Col xs={24}>
          {selectedTab === SettingsTabs.BUSINESS_INFO && <BusinessInfo />}
          {selectedTab === SettingsTabs.LOCATIONS && <Locations />}
          {selectedTab === SettingsTabs.DOMAIN && <Domain />}
          {selectedTab === SettingsTabs.SUBSCRIPTION && <Subscription />}
          {selectedTab === SettingsTabs.ACCOUNT && <Account />}
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
