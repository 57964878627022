import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import { onClickCopy } from "helpers/copy-helper";

const Domain: React.FC = () => {
  return (
    <Card className="domain">
      <Row justify="center">
        <Col xs={24}>
          <div className="disclaimer">
            <span className="text-regular">
              <ExclamationCircleOutlined /> To embed on your website please use
              this DNS Records
            </span>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={[20, 20]} style={{ marginTop: 32 }}>
        <Col xs={8}>
          <Typography.Text>Record Type</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">CNAME</Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text>Host</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value">sauce</Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text>Value</Typography.Text>
        </Col>
        <Col xs={16}>
          <Typography.Text className="value flex-gap-4">
            brandname.sauce.mustard.love
            <CopyIcon
              onClick={() => onClickCopy("brandname.sauce.mustard.love")}
              style={{ cursor: "pointer" }}
            />
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default Domain;
