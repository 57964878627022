import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Subscription } from "interfaces/subscription";
import {
  getBusinessById,
  getBusinessesLoggedUser,
  getSubscriptionInfo,
} from "services/SauceService";

export const getBusinessInfo = createAsyncThunk(
  "business/getBusinessInfo",
  async () => {
    const businessId = localStorage.getItem("businessId");
    if (!businessId) {
      return {};
    }
    const businessInfo: any = await getBusinessById(businessId);
    return businessInfo;
  }
);

export const getBusinessess = createAsyncThunk(
  "business/getBusinesses",
  async () => {
    const businesses: any = await getBusinessesLoggedUser();
    return businesses;
  }
);

export const getBusinessSubscription = createAsyncThunk(
  "business/getBusinessSubscription",
  async (includeStripeDetails?: boolean) => {
    const businessId = localStorage.getItem("businessId");
    if (!businessId) {
      return {};
    }

    const subscription: any = await getSubscriptionInfo({
      businessId,
      includeStripeDetails,
    });
    return subscription;
  }
);

export type BusinessState = {
  business: {
    business: any;
    subscription: Subscription;
    businessess: any[];
    loading: boolean;
  };
};

export const businessSlice: any = createSlice({
  name: "business",
  initialState: {
    loading: true,
    business: {},
    subscription: {},
    businessess: [],
  },
  reducers: {
    setBusiness: (state, action) => {
      state.businessess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.business = action.payload;
      }
      state.loading = false;
    });

    builder.addCase(getBusinessInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBusinessess.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getBusinessess.fulfilled, (state, action) => {
      state.businessess = action.payload;
      state.loading = false;
    });

    builder.addCase(getBusinessSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload;
      state.loading = false;
    });
  },
});

export const { setBusiness } = businessSlice.actions;

export default businessSlice.reducer;
