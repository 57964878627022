import { Select, Slider } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { getPlacesByName } from "services/SauceService";

const LocationField: React.FC<any> = (props) => {
  const { onChange } = props;

  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(20);
  const [places, setPlaces] = useState<any[]>([]);

  const getLocation = useCallback(async (locationName: string) => {
    setLoadingLocation(true);
    const response: any = await getPlacesByName({ name: locationName });
    setPlaces(
      response.map((place: any) => ({
        value: place.place_id,
        label: place.name,
        ...place,
      }))
    );
    setLoadingLocation(false);
  }, []);

  const handleSearch = (newValue: string) => {
    if (newValue) getLocation(newValue);
  };

  const handleChange = (_: string, option: any) => {
    onChange({
      latitude: option?.geometry?.location?.lat,
      longitude: option?.geometry?.location?.lng,
      maxMilesDistance: sliderValue,
      name: option.name,
    });
  };

  return (
    <div>
      <Select
        placeholder="Typing the name and address"
        showSearch
        filterOption={false}
        onSearch={debounce(handleSearch, 500)}
        onSelect={handleChange}
        options={places}
        loading={loadingLocation}
        allowClear
        optionRender={(option: any) => {
          return (
            <>
              <div style={{ padding: "8px", cursor: "pointer" }}>
                {`${option.data.name} - ${option.data.formatted_address}`}
              </div>
            </>
          );
        }}
      />
      <div>
        <span>Radius in miles</span>
        <Slider
          value={sliderValue}
          onChange={(value) => setSliderValue(value)}
          min={20}
          step={20}
          max={100}
          style={{ width: 400 }}
          marks={{
            20: "20",
            40: "40",
            60: "60",
            80: "80",
            100: "100",
          }}
        />
      </div>
    </div>
  );
};

export default LocationField;
