import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  List,
  message,
  Row,
  Select,
  Spin,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import CardGraphTrend from "./CardGraphTrend";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { ReactComponent as PlayIcon } from "assets/images/play-icon.svg";
import { ReactComponent as HeartIcon } from "assets/images/heart-icon.svg";
import { ReactComponent as ReachIcon } from "assets/images/reach-icon.svg";
import { ReactComponent as FlashIcon } from "assets/images/flash-icon.svg";
import { ReactComponent as MessageIcon } from "assets/images/message-text-icon.svg";
import { ReactComponent as PurchaseIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as LinkIcon } from "assets/images/link-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as RepeatIcon } from "assets/images/repeat-icon.svg";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import "./Report.scss";
import {
  ArrowUpOutlined,
  CrownOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  ImgWithErrorHandling,
  formatNumberKMBT,
} from "pages/influencers/InfluencerCard";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ModalInstructions from "./ModalInstructions";
import { isMobile } from "react-device-detect";
import { FacebookSDKLoader, handleFacebookLogin } from "helpers/facebook-auth";
import { connectSocialMedia } from "services/SauceService";
import { RangePickerProps } from "antd/es/date-picker";
const { RangePicker } = DatePicker;

type NoUndefinedRangeValueType<DateType> = [
  start: DateType | null,
  end: DateType | null
];

interface Pending {
  basic: number;
  premium: number;
  total: number;
  list: {
    avatarUrl: string;
    bookDate: string;
    contentDueDate: string;
    contentPublished: boolean;
    isPremium: boolean;
    username: string;
    venueName: string;
    venueAddress: string;
  }[]; // You can replace `any` with a specific type if you have more information about the structure of this
}
interface BusinessReport {
  //reports/collabs-stats
  applied: {
    premium: number;
    basic: number;
    total: number;
    images: {
      avatarUrl: string;
      isPremium: boolean;
    }[];
  };
  booked: {
    premium: number;
    basic: number;
    total: number;
    images: {
      avatarUrl: string;
      isPremium: boolean;
    }[];
  };
  invited: {
    premium: number;
    basic: number;
    total: number;
    sumFollowers?: number;
    images: {
      avatarUrl: string;
      isPremium: boolean;
    }[];
  };
  collabs: {
    totalViews: number;
    totalLikes: number;
    totalReach: number;
    totalComments: number;
    totalDinner: number;
    totalReshares: number;
    dinnerByPosts: any[]; // You can replace `any` with a specific type if you have more information about the structure of this array
    postsCollab: any[]; // You can replace `any` with a specific type if you have more information about the structure of this array
    averageEngagementRate: number;
  };
  pending: Pending;

  //reports/comments-stats
  commentsCategories: any; // You can replace `any` with a specific type if you have more information about the structure of this array
  commentsHeatsTime: {
    friday: number[];
    monday: number[];
    saturday: number[];
    wednesday: number[];
    thursday: number[];
    sunday: number[];
  }; // You can replace `any` with a specific type if you have more information about the structure of this array
  commentsCollab: {
    socialMediaUrl: string;
    socialMediaName: string;
    commentAvatarUrl: string;
    commentText: string;
    commentUserName: string;
    commentDate: number;
    commentScoreSort: number;
  }[]; // You can replace `any` with a specific type if you have more information about the structure of this array

  //reports/social-media-stats
  overallReachData: {
    date: string;
    profileReach: string;
    profileImpressions: string;
  }[];
  accounts: {
    socialMediaName: string;
    accountName: string;
    reachData: {
      date: string;
      profileReach: string;
    }[];
    reachAmount: number;
    reachPercentage: number;
    followersData: {
      date: string;
      accumulative: string;
    }[];
    followersAmount: number;
    followersPercentage: number;
    engagementData: {
      date: string;
      engagementRate: string;
    }[];
    engagementAmount: number;
    engagementPercentage: number;
  }[];

  // extra fields
  title: string;
  subtitle: string;
  businessId: string;
  venueUuid: string;
  startDate: string;
  endDate: string;
}

const weekdays = [
  "Saturday",
  "Friday",
  "Thursday",
  "Wednesday",
  "Tuesday",
  "Monday",
  "Sunday",
];

const chartOptionsDefault: any = (type: string) => ({
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    type: type,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
});

const profileReactData: ApexOptions = {
  ...{
    ...chartOptionsDefault("bar"),
    yaxis: {
      opposite: true,
      labels: {
        formatter: function (value) {
          return formatNumberKMBT(value);
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM dd",
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: "16px",
    },
  },

  stroke: {
    width: [0, 4],
    curve: "smooth",
  },
  colors: ["#39A0FF", "#FFAC39"],
};

const chartCommentsHeatsTime: ApexOptions = {
  ...{
    ...chartOptionsDefault("heatmap"),
    legend: { show: false },
    yaxis: { labels: { show: true } },
  },
  colors: ["#ff7238"],
  xaxis: {
    type: "category",
    categories: [
      "12 am",
      "1 am",
      "2 am",
      "3 am",
      "4 am",
      "5 am",
      "6 am",
      "7 am",
      "8 am",
      "9 am",
      "10 am",
      "11 am",
      "12 pm",
      "1 pm",
      "2 pm",
      "3 pm",
      "4 pm",
      "5 pm",
      "6 pm",
      "7 pm",
      "8 pm",
      "9 pm",
      "10 pm",
      "11 pm",
    ].slice(10, 23),
  },
  plotOptions: {
    heatmap: {
      radius: 6,
      distributed: true,
      colorScale: {
        ranges: [
          {
            from: -1, // Qualquer valor menor que 0
            to: 0,
            color: "#F6F6F6", // Cor cinza para valores nulos ou ausentes
          },
          // Outras faixas de cores podem ser adicionadas aqui
        ],
      },
    },
  },
};

const chartWhatPeopleLove: ApexOptions = {
  ...chartOptionsDefault("donut"),
  colors: ["#FF4E38", "#ff7238", "#FF9338", "#FFB338", "#FFCB38"],
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: "40%",
      },
    },
  },
  legend: {
    fontSize: 16,
    offsetY: 20,
    markers: {
      width: 16,
      height: 16,
      offsetX: -4,
      offsetY: 2,
    },
  },
};

const CollabReport: React.FC = () => {
  const {
    applied,
    booked,
    invited,
    collabs,
    pending,
    commentsHeatsTime,
    commentsCategories,
    commentsCollab,
    overallReachData,
    accounts,
    businessId,
    venueUuid,
    subtitle,
    title,
    startDate,
    endDate,
  } = (useLoaderData() as BusinessReport) || {};

  const navigation = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const exporting = location.pathname.includes("/export");
  const loading = navigation.state === "loading";
  const { business } = useSelector((state: any) => state.business);
  const [commentsPage, setCommentsPage] = useState<number>(0);
  const [pendingPage, setPendingPage] = useState<number>(0);
  const [showModalInstructions, setShowModalInstructions] =
    useState<boolean>(false);
  const [connectSocialLoading, setConnectSocialLoading] =
    useState<boolean>(false);

  const [venueOptions, setVenueOptions] = useState<any>([
    { value: "all", label: "All Locations" },
  ]);

  const commentsPeopleLoveData = useMemo(() => {
    if (!commentsCategories) return [];

    return Object.keys(commentsCategories).map(
      (key) => (commentsCategories as any)[key]
    );
  }, [commentsCategories]);

  const overAllReachDataSeries = useMemo(() => {
    if (!overallReachData || overallReachData?.length === 0) return [];

    return overallReachData.reduce(
      (acc: any[], item) => {
        acc[0].data.push(item.profileReach);
        acc[1].data.push(item.profileImpressions);

        return acc;
      },
      [
        {
          name: "Profile reach",
          type: "column",
          data: [],
        },
        {
          name: "Profile impressions",
          type: "line",
          data: [],
        },
      ]
    );
  }, [overallReachData]);

  const commentsTimeData = useMemo(() => {
    if (!commentsHeatsTime) return [];
    return weekdays.map((key) => {
      return {
        name: key?.slice(0, 3),
        data:
          (commentsHeatsTime as any)[key]?.slice(10, 23) || Array(13).fill(0),
      };
    });
  }, [commentsHeatsTime]);

  const localCommentsPaginated = useMemo(() => {
    if (!commentsCollab) return [];
    return commentsCollab.slice(0, commentsPage * 5 + 5);
  }, [commentsCollab, commentsPage]);

  const localPendingPaginated = useMemo(() => {
    if (!pending?.list) return [];
    return pending?.list.slice(0, pendingPage * 5 + 3);
  }, [pending.list, pendingPage]);

  const instagramAccount = useMemo(() => {
    if (!accounts) return null;
    return accounts.filter(
      (account) => account.socialMediaName?.toLowerCase() === "instagram"
    );
  }, [accounts]);

  const titkokAccount = useMemo(() => {
    if (!accounts) return null;
    return accounts.filter(
      (account) => account.socialMediaName?.toLowerCase() === "tiktok"
    );
  }, [accounts]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const onLoadMorePending = () => {
    setPendingPage((prev) => prev + 1);
  };

  const onLoadMoreComments = () => {
    setCommentsPage((prev) => prev + 1);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  const onClickOpenPost = (url: string) => {
    if (!url) return;
    window.open(url, "_blank", "noopener");
  };

  const buildUrl = (params: Record<string, string | undefined>) => {
    const filteredParams: Record<string, string> = {};
    for (const key in params) {
      if (params[key] !== undefined && params[key] !== null) {
        filteredParams[key] = params[key] as string;
      }
    }
    const url = new URLSearchParams(filteredParams);
    return `/report?${url.toString()}`;
  };

  const onChangeLocation = (value: string) => {
    const params: Record<string, string | undefined> = {
      businessId,
      venueUuid: value !== "all" ? value : undefined,
      startDate,
      endDate,
    };

    if (value === "all") {
      delete params.venueUuid;
    }

    navigate(buildUrl(params));
  };

  const onChangeRangeDate = (
    value: NoUndefinedRangeValueType<dayjs.Dayjs> | null,
    dateStrings: [string, string]
  ) => {
    const params: Record<string, string | undefined> = {
      businessId,
      venueUuid,
    };

    if (value && dateStrings) {
      params.startDate = dateStrings[0];
      params.endDate = dateStrings[1];
    } else {
      delete params.startDate;
      delete params.endDate;
    }

    navigate(buildUrl(params));
  };

  const onClickExport = () => {
    const urlparams = new URLSearchParams(window.location.search);

    const url = `/report/export?${urlparams.toString()}`;
    window.open(url, "_blank");
  };

  const handleFacebookResponse = async (response: any) => {
    if (response.status === "connected") {
      const businessId = localStorage.getItem("businessId");
      const accessToken = response.authResponse.accessToken;
      setConnectSocialLoading(true);
      try {
        await connectSocialMedia({
          businessId,
          accessToken,
        });
        setConnectSocialLoading(false);
        message.success("Meta Businees connected successfully");
        navigate(`/report?businessId=${businessId}`, { replace: true });
      } catch {
        setConnectSocialLoading(false);
      }
    } else {
      console.log("User is not logged in with Facebook");
    }
  };

  const onClickSigninMeta = () => {
    handleFacebookLogin(handleFacebookResponse);
  };

  useEffect(() => {
    if (business) {
      const businessVenue =
        business.venues?.map((venue: any) => ({
          value: venue.uuid,
          label: venue.name,
        })) || [];
      setVenueOptions([
        { value: "all", label: "All Locations" },
        ...businessVenue,
      ]);
    }
  }, [business]);

  if (loading || connectSocialLoading) return <Spin />;

  if (isMobile)
    return (
      <div style={{ textAlign: "center", marginTop: 80 }}>
        <Typography.Text style={{ fontSize: 16 }}>
          This feature can be accessible on the web version.
        </Typography.Text>
      </div>
    );

  return (
    <div className="report" id="report">
      <Row>
        {!exporting && (
          <Button
            onClick={onClickExport}
            iconPosition="end"
            icon={
              <ArrowUpOutlined rotate={45} className="icon-url" size={20} />
            }
          >
            Preview export
          </Button>
        )}
      </Row>
      <Typography.Title level={1}>{title}</Typography.Title>
      <Typography.Text
        style={{ display: "block", marginBottom: 40, fontSize: 16 }}
      >
        {subtitle}
      </Typography.Text>
      <Row justify="end" style={{ display: !businessId ? "none" : "flex" }}>
        <RangePicker
          format="YYYY-MM-DD"
          onChange={onChangeRangeDate}
          onOk={(value: RangePickerProps["value"]) => {
            console.log("onOk: ", value);
          }}
          value={
            startDate ? ([dayjs(startDate), dayjs(endDate)] as any) : undefined
          }
        />
        <div>
          <Select
            variant="borderless"
            value={venueUuid || "all"}
            options={venueOptions}
            onChange={onChangeLocation}
            popupMatchSelectWidth={false}
          />
        </div>
      </Row>
      {/* <Row>
        <Col xs={24}>
        <Card>
        <ReactApexChart
        options={overallReachData}
        series={overallReachData.series}
        height={350}
        type="area"
        />
        </Card>
        </Col>
        </Row>
        <Typography.Title level={3} style={{marginTop:40}}>
        Profile Reach and Impressions
      </Typography.Title> */}
      {overAllReachDataSeries.length > 0 && (
        <>
          <Typography.Title level={3} style={{ marginTop: 40 }}>
            Social media accounts performance
          </Typography.Title>
          <Row>
            <Col xs={24}>
              <Card>
                <ReactApexChart
                  options={{
                    ...profileReactData,
                    labels: overallReachData?.map((item) => item.date) || [],
                  }}
                  series={overAllReachDataSeries}
                  height={350}
                  type="line"
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
      {instagramAccount?.length ? (
        instagramAccount.map((account) => (
          <>
            <Typography.Title level={4}>
              Instagram {account.accountName}
            </Typography.Title>
            <Row gutter={8}>
              <Col xs={8}>
                <CardGraphTrend
                  platform="Instagram"
                  username={account.accountName}
                  title="Reach"
                  data={{
                    value: formatNumberKMBT(account.reachAmount),
                    percentage: account.reachPercentage,
                    data: account.reachData,
                  }}
                  dataProp="profileReach"
                />
              </Col>
              <Col xs={8}>
                <CardGraphTrend
                  platform="Instagram"
                  username={account.accountName}
                  title="Followers"
                  data={{
                    value: formatNumberKMBT(account.followersAmount),
                    percentage: account.followersPercentage,
                    data: account.followersData,
                  }}
                  dataProp="accumulative"
                />
              </Col>
              <Col xs={8}>
                <CardGraphTrend
                  platform="Instagram"
                  username={account.accountName}
                  title="Account engagement rate"
                  data={{
                    value: account.engagementAmount?.toFixed(2),
                    percentage: account.engagementPercentage,
                    data: account.engagementData,
                  }}
                  dataProp="engagementRate"
                />
              </Col>
            </Row>
          </>
        ))
      ) : (
        <div>
          <Typography.Title level={3}>
            Social media accounts performance
          </Typography.Title>
          <Row>
            <Col xs={24}>
              <FacebookSDKLoader />
              <Card>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 40,
                  }}
                >
                  <Typography.Title level={5}>
                    Add Us on Meta Suite
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      marginBottom: 32,
                      maxWidth: 400,
                      textAlign: "center",
                      fontFamily: "Inter",
                    }}
                  >
                    To get the most out of Mustard's influencer platform, please
                    connect Mustard as a business partner on Meta Business
                    Account.
                    <br />
                    <b>
                      You could get a higher ROI by connecting your Meta
                      Business Account
                    </b>
                  </Typography.Text>
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={onClickSigninMeta}
                  >
                    Connect Meta Business
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
          <ModalInstructions
            open={showModalInstructions}
            onCancel={() => setShowModalInstructions(false)}
          />
        </div>
      )}
      {titkokAccount?.length ? (
        titkokAccount.map((account) => (
          <>
            <Typography.Title level={4}>
              TikTok {account.accountName}
            </Typography.Title>
            <Row gutter={8}>
              <Col xs={8}>
                <CardGraphTrend
                  platform="TikTok"
                  username={account.accountName}
                  title="Reach"
                  data={{
                    value: formatNumberKMBT(account.reachAmount),
                    percentage: account.reachPercentage,
                    data: account.reachData,
                  }}
                  dataProp="profileReach"
                />
              </Col>
              <Col xs={8}>
                <CardGraphTrend
                  platform="TikTok"
                  username={account.accountName}
                  title="Followers"
                  data={{
                    value: formatNumberKMBT(account.followersAmount),
                    percentage: account.followersPercentage,
                    data: account.followersData,
                  }}
                  dataProp="accumulative"
                />
              </Col>
              <Col xs={8}>
                <CardGraphTrend
                  platform="TikTok"
                  username={account.accountName}
                  title="Account engagement rate"
                  data={{
                    value: account.engagementAmount,
                    percentage: account.engagementPercentage,
                    data: account.engagementData,
                  }}
                  dataProp="engagementRate"
                />
              </Col>
            </Row>
          </>
        ))
      ) : (
        <></>
      )}
      <Typography.Title level={3} style={{ marginTop: 40 }}>
        Participating Influencers
      </Typography.Title>
      <Row>
        <Col xs={24}>
          <Card className="no-padding">
            <Row gutter={8}>
              <Col xs={8}>
                <div style={{ padding: 40 }}>
                  <div style={{ display: "flex", gap: 12 }}>
                    <Statistic
                      title="Invited"
                      value={invited?.total}
                      className="influencer-card-statistics"
                    />
                    <div>
                      <span className="invited-text">
                        {invited?.basic} Basic
                      </span>
                      <span className="invited-text">
                        {invited?.premium} Premium
                      </span>
                    </div>
                  </div>

                  <div>
                    <Avatar.Group
                      className="avatar-group"
                      max={{ count: 47 }}
                      size="default"
                      style={{ marginTop: 20 }}
                    >
                      {invited?.images.map((image, index) => (
                        <Badge
                          key={`${index}_${image.avatarUrl}`}
                          count={
                            image.isPremium ? (
                              <CrownOutlined
                                style={{
                                  background: "#faad14",
                                  color: "#1D2023",
                                  borderRadius: "50%",
                                  padding: 3,
                                  fontSize: 6,
                                }}
                              />
                            ) : (
                              ""
                            )
                          }
                          color="#faad14"
                          offset={[-8, 0]}
                        >
                          <Avatar src={image.avatarUrl} />
                        </Badge>
                      ))}
                    </Avatar.Group>
                  </div>
                </div>
              </Col>
              <Divider type="vertical" />
              <Col xs={8}>
                <div style={{ padding: 40 }}>
                  <div style={{ display: "flex", gap: 12 }}>
                    <Statistic
                      title="Applied"
                      value={applied?.total}
                      className="influencer-card-statistics"
                    />
                    <div>
                      <span className="invited-text">
                        {applied?.basic} Basic
                      </span>
                      <span className="invited-text">
                        {applied?.premium} Premium
                      </span>
                    </div>
                  </div>
                  <div>
                    <Avatar.Group
                      className="avatar-group medium"
                      max={{ count: 20 }}
                      size={50}
                      style={{ marginTop: 20 }}
                    >
                      {applied?.images.map((image, index) => (
                        <Badge
                          key={`${index}_${image.avatarUrl}`}
                          count={
                            image.isPremium ? (
                              <CrownOutlined
                                style={{
                                  background: "#faad14",
                                  color: "#1D2023",
                                  borderRadius: "50%",
                                  padding: 3,
                                  fontSize: 6,
                                }}
                              />
                            ) : (
                              ""
                            )
                          }
                          color="#faad14"
                          offset={[-8, 0]}
                        >
                          <Avatar src={image.avatarUrl} />
                        </Badge>
                      ))}
                    </Avatar.Group>
                  </div>
                </div>
              </Col>
              <Divider type="vertical" />
              <Col xs={7}>
                <div style={{ padding: 40 }}>
                  <div style={{ display: "flex", gap: 12 }}>
                    <Statistic
                      title="Booked"
                      value={booked?.total}
                      className="influencer-card-statistics"
                    />
                    <div>
                      <span className="invited-text">
                        {booked?.basic} Basic
                      </span>
                      <span className="invited-text">
                        {booked?.premium} Premium
                      </span>
                    </div>
                  </div>
                  <div>
                    <Avatar.Group
                      className="avatar-group large"
                      max={{ count: 10 }}
                      size={70}
                      style={{ marginTop: 20 }}
                    >
                      {booked?.images.map((image, index) => (
                        <Badge
                          key={`${index}_${image.avatarUrl}`}
                          count={
                            image.isPremium ? (
                              <CrownOutlined
                                style={{
                                  background: "#faad14",
                                  color: "#1D2023",
                                  borderRadius: "50%",
                                  padding: 3,
                                  fontSize: 6,
                                }}
                              />
                            ) : (
                              ""
                            )
                          }
                          color="#faad14"
                          offset={[-8, 0]}
                        >
                          <Avatar src={image.avatarUrl} />
                        </Badge>
                      ))}
                    </Avatar.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={23}>
                <Divider style={{ margin: 0 }} />
              </Col>
            </Row>
            <Row style={{ padding: 24 }}>
              <Col xs={12}>
                <Typography.Title level={5} style={{ margin: "auto" }}>
                  Total local followers
                </Typography.Title>
              </Col>
              <Col xs={12}>
                <Flex justify="flex-end">
                  <Statistic value={invited?.sumFollowers} />
                </Flex>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {collabs?.postsCollab.length > 0 && (
        <>
          <Typography.Title level={3} style={{ marginTop: 40 }}>
            Collaborative content performance
          </Typography.Title>
          <Row gutter={[12, 12]}>
            <Col xs={8}>
              <Card className="card-performance">
                <PlayIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {formatNumberKMBT(collabs.totalViews)}
                </Typography.Title>
                <span>Views</span>
              </Card>
            </Col>
            <Col xs={8}>
              <Card className="card-performance">
                <HeartIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {formatNumberKMBT(collabs.totalLikes)}
                </Typography.Title>
                <span>Likes</span>
              </Card>
            </Col>
            <Col xs={8}>
              <Card className="card-performance">
                <RepeatIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {formatNumberKMBT(collabs.totalReshares)}
                </Typography.Title>
                <span>Shares</span>
              </Card>
            </Col>
            <Col xs={8}>
              <Card className="card-performance">
                <ReachIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {formatNumberKMBT(collabs.totalReach)}
                </Typography.Title>
                <span>Reach</span>
              </Card>
            </Col>
            <Col xs={8}>
              <Card className="card-performance">
                <MessageIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {collabs.totalComments}
                </Typography.Title>
                <span>Comments</span>
              </Card>
            </Col>
            <Col xs={8}>
              <Card className="card-performance">
                <FlashIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {collabs.averageEngagementRate}%
                </Typography.Title>
                <span>Engagement rate</span>
              </Card>
            </Col>
          </Row>

          <Typography.Title level={4}>Posts</Typography.Title>
          <Row gutter={[12, 12]}>
            <Col xs={24} className="list-post">
              {collabs?.postsCollab.map((post, index) => (
                <div
                  className="post"
                  key={`POST_${index}_${post.engagementRate}`}
                  onClick={() => onClickOpenPost(post.socialMediaUrl)}
                >
                  <ImgWithErrorHandling src={post.coverImg} alt="" />
                  <span className="social-icon">
                    {post.socialMediaName?.toLowerCase() === "tiktok" ? (
                      <TikTokIcon className="stroke" />
                    ) : (
                      <InstagramIcon className="fill" />
                    )}
                  </span>
                  <div className="info">
                    <span className="text">
                      <FlashIcon /> {post.engagementRate || "-"}% Eng
                    </span>
                    <span className="text">
                      <PlayIcon /> {formatNumberKMBT(post.reach) || "-"} views
                    </span>
                    {!!post.dinner && (
                      <span className="text">
                        <PurchaseIcon />
                        {post.dinner || "-"} Purchase Intent
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}
      {pending?.list.length > 0 && (
        <>
          <Typography.Title level={4}>Pending</Typography.Title>
          <Row gutter={[12, 12]}>
            <Col xs={24}>
              <Row className="pending-header">
                <Col xs={14}>
                  <ExclamationCircleOutlined />{" "}
                  {`${pending?.list?.length} posts are in preparation`}
                </Col>
              </Row>
            </Col>
            {localPendingPaginated.map((pending, index) => (
              <Col xs={24} key={`PENDING_${index}_${pending.avatarUrl}`}>
                <Card>
                  <Row>
                    <Col xs={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        <Badge
                          key={`${index}_${pending.avatarUrl}`}
                          count={
                            pending.isPremium ? (
                              <CrownOutlined
                                style={{
                                  background: "#faad14",
                                  color: "#1D2023",
                                  borderRadius: "50%",
                                  padding: 3,
                                  fontSize: 12,
                                }}
                              />
                            ) : (
                              ""
                            )
                          }
                          color="#faad14"
                          offset={[-10, 4]}
                        >
                          <Avatar src={pending.avatarUrl} size={60} />
                        </Badge>
                        <span
                          className="pending-icon-value"
                          style={{ fontSize: 16 }}
                        >
                          {pending.username}
                        </span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <span className="pending-icon-value">
                        <LocationIcon />
                        <span>{pending.venueName}</span>
                      </span>
                      <span className="pending-icon-label">
                        {pending.venueAddress}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="pending-icon-value">
                        <CalendarIcon />{" "}
                        {dayjs.utc(pending.bookDate).format("MMM, DD")}
                      </span>
                      <span className="pending-icon-label">Date of visit</span>
                    </Col>
                    <Col xs={4}>
                      <span className="pending-icon-value">
                        <PlayIcon />
                        {dayjs.utc(pending.contentDueDate).format("MMM, DD")}
                      </span>
                      <span className="pending-icon-label">
                        Date of content due
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
            {localPendingPaginated.length < pending?.list?.length && (
              <Col
                xs={24}
                style={{
                  marginBottom: 20,
                  height: 32,
                  lineHeight: "32px",
                }}
              >
                <Button
                  type="primary"
                  onClick={onLoadMorePending}
                  style={{ width: "100%" }}
                >
                  load more
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
      {collabs?.postsCollab.length > 0 && (
        <>
          <Typography.Title level={4}>Comments</Typography.Title>
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <Card className="card-performance">
                <PurchaseIcon className="icon" />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {collabs.totalDinner}
                </Typography.Title>
                <span>Total purchase intent </span>
                <Tooltip
                  title="Purchase intent is counted when people in the comments express interest in visiting the venue or trying the product featured in the video."
                  className="tooltip-info"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Card>
            </Col>
            <Col xs={12}>
              <Card className="card-performance">
                <PurchaseIcon className="icon" />

                <Typography.Title level={5} style={{ margin: 0 }}>
                  {collabs.dinnerByPosts}/post
                </Typography.Title>
                <span>Purchase intent</span>
              </Card>
            </Col>
          </Row>

          {commentsCategories && (
            <>
              <Typography.Title level={4}>What people love</Typography.Title>
              <Row>
                <Col xs={24}>
                  <Card>
                    <ReactApexChart
                      options={{
                        ...chartWhatPeopleLove,
                        labels: commentsCategories
                          ? Object.keys(commentsCategories)
                          : [],
                      }}
                      series={commentsPeopleLoveData}
                      height={260}
                      width={1000}
                      type="donut"
                    />
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {commentsTimeData.length > 0 && (
            <>
              <Typography.Title level={4}>Comments time</Typography.Title>
              <Row>
                <Col xs={24}>
                  <Card>
                    <ReactApexChart
                      options={{
                        ...chartCommentsHeatsTime,
                      }}
                      series={commentsTimeData}
                      height={300}
                      type="heatmap"
                    />
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Typography.Title level={4}>
            Comments ({commentsCollab?.length})
          </Typography.Title>
          <Row>
            <Col xs={24}>
              <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                loadMore={
                  localCommentsPaginated.length < commentsCollab.length && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 20,
                        height: 32,
                        lineHeight: "32px",
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={onLoadMoreComments}
                        style={{ width: "100%" }}
                      >
                        load more
                      </Button>
                    </div>
                  )
                }
                dataSource={localCommentsPaginated}
                renderItem={(item) => (
                  <Card style={{ marginBottom: 12 }} className="card-comment">
                    <List.Item>
                      <Col xs={24}>
                        <Row justify="space-between">
                          <Col xs={12}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 12,
                                marginBottom: 18,
                              }}
                            >
                              <Avatar src={item.commentAvatarUrl} size={38} />
                              <Typography.Text>
                                {`${item.commentUserName} ∘ ${dayjs
                                  .unix(item.commentDate)
                                  .fromNow()}`}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: 20,
                            }}
                          >
                            {item.socialMediaName?.toLowerCase() ===
                            "instagram" ? (
                              <InstagramIcon
                                style={{ fontSize: 20 }}
                                className="stroke"
                              />
                            ) : (
                              <TikTokIcon
                                style={{ fontSize: 20 }}
                                className="fill"
                              />
                            )}

                            {item?.socialMediaUrl && (
                              <a
                                href={item?.socialMediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LinkIcon className="icon-export" />
                              </a>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Typography.Text>{item.commentText}</Typography.Text>
                        </Row>
                      </Col>
                    </List.Item>
                  </Card>
                )}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CollabReport;
