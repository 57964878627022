import { Form, Input, Typography } from "antd";
import { InputMask } from "@react-input/mask";
import useToken from "antd/es/theme/useToken";

interface Props {
  // Define the props for your component here
}

const OnboardingStart: React.FC<Props> = () => {
  const [, , hashId] = useToken();
  return (
    <div>
      <Typography.Title level={2}>
        Give Mustard a try. It's free.
      </Typography.Title>
      <Typography.Paragraph>
        Grow your business with influencer marketing and reach new local
        audiences on social media.
      </Typography.Paragraph>

      <Form.Item
        name="firstName"
        rules={[{ required: true, message: "First name is required" }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: "Last name is required" }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Email is not valid" },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[{ required: true, message: "Phone number is required" }]}
      >
        <InputMask
          mask="+1 ___-___-____"
          replacement={{ _: /\d/ }}
          className={`ant-input ant-input-outlined ${hashId}`}
          placeholder="+1 ___-__-__"
        />
      </Form.Item>
    </div>
  );
};

export default OnboardingStart;
