import { Card, Divider, Spin, Typography } from "antd";
import { Chat } from "enums/ChatType";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import EmptyIllustrationSrc from "assets/images/messenger-empty.png";
import { RowMessage } from "./components/RowMessage";
import "./Messenger.scss";
import { useDispatch, useSelector } from "react-redux";
import { addNewChat, addNewMessage, getAllChats } from "redux-context/chat";
import { sseService } from "services/SSEStream";

const Messenger: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { chats, loading } = useSelector((state: any) => state.chat);
  const navigate = useNavigate();

  const newChatSubscribeRef = useRef<() => void>();
  const newMessageSubscribeRef = useRef<() => void>();

  useEffect(() => {
    dispatch(getAllChats());

    newChatSubscribeRef.current = sseService.subscribe(
      "chat/new-chat",
      (event) => {
        console.log("chat/new-chat");
        const chat = JSON.parse(event.data) as Chat;
        dispatch(addNewChat(chat));
      }
    );

    newMessageSubscribeRef.current = sseService.subscribe(
      "chat/new-message",
      (event) => {
        console.log("chat/new-message");
        const message = JSON.parse(event.data) as Chat["messages"][0];
        dispatch(addNewMessage(message));
      }
    );

    return () => {
      newChatSubscribeRef.current?.();
      newMessageSubscribeRef.current?.();
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div>
        <Typography.Title>Messenger</Typography.Title>
        <Card className="messenger__card">
          <Spin />
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title>Messenger</Typography.Title>

      <Card>
        {!loading && chats.length === 0 && (
          <div className="messenger__empty">
            <div className="messenger__empty-container">
              <img src={EmptyIllustrationSrc} alt="empty" />
              <Typography.Title level={3}>No chats yet</Typography.Title>
              <Typography.Text>Your chat history is empty</Typography.Text>
            </div>
          </div>
        )}
        <div className="messenger__chat-grid">
          {chats.map((chat: Chat, index: number) => (
            <>
              <RowMessage
                key={chat._id}
                chat={chat}
                onClick={() => navigate(`/messenger/${chat._id}`)}
              />
              {index < chats.length - 1 && <Divider />}
            </>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default Messenger;
