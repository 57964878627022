import { ArrowLeftOutlined, SendOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessMessage } from "./components/BusinessMessage";
import { FoodieMessage } from "./components/FoodieMessage";
import { MustardMessage } from "./components/MustardMessage";
import { useCallback, useEffect, useRef, useState } from "react";
import { getChat, markChatAsRead, sendMessage } from "services/SauceService";
import { UserState } from "redux-context/user";
import { useSelector } from "react-redux";
import { Chat as ChatType } from "enums/ChatType";
import "./Chat.scss";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { sseService } from "services/SSEStream";

const createDummyMessage = ({
  content,
  loggedUser,
  senderType,
}: {
  content: string;
  loggedUser: any;
  senderType: "business" | "mustard" | "mustard-on-behalf-of-business";
}) => {
  return {
    content,
    messageType: "text",
    state: "sending",
    senderType,
    sender: {
      _id: loggedUser._id,
      name: senderType === "mustard" ? "Mustard" : loggedUser.name,
      avatarUrl:
        senderType === "mustard"
          ? "https://app-collabs.mustard.love/logo192.png"
          : loggedUser.avatarUrl,
    },
    _id: Date.now().toString(),
    createdAt: new Date(),
  };
};

export default function Chat() {
  const { id } = useParams();
  const businessId = localStorage.getItem("businessId");
  const [chat, setChat] = useState<ChatType | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { loggedUser } = useSelector((state: UserState) => state.user);
  const isMustardUser = loggedUser?.email?.endsWith("@mustard.love");
  const [senderType, setSenderType] = useState<
    "mustard" | "mustard-on-behalf-of-business" | "business"
  >();

  const newMessageSubscribeRef = useRef<() => void>();

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, 0);
  }, [messagesContainerRef]);

  const markAsRead = useCallback(async () => {
    if (!id || !businessId) return;

    try {
      console.log("marking chat as read");
      await markChatAsRead(id, businessId);
    } catch (error) {
      //ignore
    }
  }, [id, businessId]);

  useEffect(() => {
    newMessageSubscribeRef.current = sseService.subscribe(
      "chat/new-message",
      (event) => {
        console.log("chat/new-message");
        const message = JSON.parse(event.data) as ChatType["messages"][0];
        setChat((prevChat) => {
          if (!prevChat) return prevChat;

          return {
            ...prevChat,
            messages: [...prevChat.messages, message],
          };
        });

        scrollToBottom();
        markAsRead();
      }
    );

    return () => {
      newMessageSubscribeRef.current?.();
    };
  }, [markAsRead, scrollToBottom]);

  useEffect(() => {
    if (isMustardUser === undefined || senderType) return;

    if (isMustardUser) {
      setSenderType("mustard-on-behalf-of-business");
    } else {
      setSenderType("business");
    }
  }, [isMustardUser, businessId, senderType]);

  const navigate = useNavigate();
  const businessParticipant = chat?.participants.find(
    (participant) => participant.participantId === businessId
  );
  const otherParticipants = chat?.participants.filter(
    (participant) => participant.participantId !== businessId
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (!chat && id && businessId) {
      getChat(id, businessId, abortController)
        .then((res) => {
          //@ts-ignore
          setChat(res);
        })
        .then(() => {
          setLoading(false);
          scrollToBottom();
          markAsRead();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendMessage = async () => {
    if (!chat || !message || isSendingMessage || message.trim().length === 0)
      return;

    const originalMessage = message;
    setMessage("");

    try {
      setIsSendingMessage(true);

      setChat((prevChat) => {
        if (!prevChat) return prevChat;

        return {
          ...prevChat,
          messages: [
            ...prevChat.messages,
            createDummyMessage({
              content: originalMessage,
              loggedUser: businessParticipant,
              senderType: senderType ?? "business",
            }) as ChatType["messages"][number],
          ],
        };
      });

      scrollToBottom();

      if (!businessParticipant?.participantId || !businessId || !senderType) {
        return;
      }

      const messageFromServer = await sendMessage(chat._id, {
        message: originalMessage,
        senderType,
        sauceUserId: loggedUser?.id,
        businessId,
      });

      setChat({
        ...chat,
        //@ts-ignore
        messages: [...chat.messages, messageFromServer],
      });

      // scrollToBottom();
    } catch (error) {
      console.error(error);
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          {
            ...(createDummyMessage({
              content: originalMessage,
              loggedUser: businessParticipant,
              senderType: senderType ?? "business",
            }) as ChatType["messages"][number]),
            state: "error",
          },
        ],
      });
    } finally {
      setIsSendingMessage(false);
    }
  };

  if (!chat || loading) {
    return (
      <div>
        <Typography.Title>Messenger</Typography.Title>
        <Card className="chat__card chat__card--loading">
          <Spin />
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title>Messenger</Typography.Title>

      <Card className="chat__card">
        <Row className="chat__header">
          <Col span={24} className="chat__header-content">
            <div className="chat__header-left">
              <div
                className="chat__header-back"
                onClick={() => navigate("/messenger")}
              >
                <ArrowLeftOutlined />
              </div>
              <Avatar src={otherParticipants?.[0]?.avatarUrl} />
              <span className="chat__header-name">
                {otherParticipants?.[0]?.participantName}
              </span>
            </div>
            <div>
              <div className="chat__header__context-info">
                <div className="chat__header__context-item chat__header__context-item--booking">
                  <CalendarIcon className="icon" />
                  <span>
                    {chat.context.booking?.bookDate
                      ? new Date(
                          chat.context.booking.bookDate
                        ).toLocaleDateString()
                      : "-"}
                  </span>
                  <ClockIcon className="icon" />
                  <span>{chat.context.booking?.bookHour ?? "-"}</span>
                </div>
                <span className="chat__header__context-item">
                  <LocationIcon className="icon" />
                  <span>{chat.context.venue?.name ?? "-"}</span>
                </span>
                <span className="chat__header__context-item">
                  <span>{chat.context.venue?.address ?? "-"}</span>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 0 }} />
        <Row className="chat__messages">
          <Col
            span={24}
            ref={messagesContainerRef}
            className="chat__messages-container"
          >
            <div ref={messagesContainerRef}>
              {chat.messages.map((message) => (
                <>
                  {message.senderType === "foodie" && (
                    <FoodieMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "business" && (
                    <BusinessMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "mustard-on-behalf-of-business" && (
                    <BusinessMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "mustard" && (
                    <MustardMessage key={message._id} {...message} />
                  )}
                  {![
                    "foodie",
                    "business",
                    "mustard",
                    "mustard-on-behalf-of-business",
                  ].includes(message.senderType) && (
                    <>
                      <div>Type: {message.senderType}</div>
                      <div className="card">{message.content}</div>
                    </>
                  )}
                </>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="chat__input-container">
            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              className="chat__input-textarea"
            />
            <div className="chat__input-actions">
              <div className="chat__input-buttons">
                <Button
                  type="primary"
                  className="chat__input-send"
                  disabled={isSendingMessage}
                  onClick={handleSendMessage}
                >
                  <SendOutlined className="chat__input-send-icon" />
                </Button>
                {isMustardUser && (
                  <Select
                    labelInValue={false}
                    suffixIcon={null}
                    className="chat__input-select"
                    dropdownClassName="chat__input-select-dropdown"
                    value={senderType}
                    onChange={(value) => setSenderType(value)}
                    options={[
                      {
                        label: <Avatar src={businessParticipant?.avatarUrl} />,
                        value: "mustard-on-behalf-of-business",
                      },
                      {
                        label: (
                          <Avatar src="https://app-collabs.mustard.love/logo192.png" />
                        ),
                        value: "mustard",
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
