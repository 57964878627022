import { Button, Form, Input, Modal, Typography, message } from "antd";
import React, { useState } from "react";
import {
  rescheduleApplicationSuggestedDates,
  // rescheduleApplicationTargetTime,
  // rescheduleBusinessApplication,
} from "services/SauceService";
import { ReactComponent as RescheduleIcon } from "assets/images/reschedule-icon.svg";
import { useNavigate } from "react-router-dom";

interface InfluencerBooking {
  bookingId: string;
  collabId: string;
  tabStatus: "accepted" | "pending" | "rejected" | "expired";
}

const convert24HourToAmPmFormat = (time: string) => {
  if (!time) return time;
  // Extract the hours and minutes from the input string
  const [hour, minute] = time.split(":").map(Number);

  // Determine the period (AM/PM)
  const period = hour < 12 ? "AM" : "PM";

  // Convert hours from 24-hour format to 12-hour format
  const hour12 = hour % 12 || 12;

  // Pad the hour with leading zero if necessary
  const formattedHour = hour12 < 10 ? "0" + hour12 : "" + hour12;

  // Pad the minute with leading zero if necessary
  const formattedMinute = minute < 10 ? "0" + minute : "" + minute;

  // Combine to form the new time string in 12-hour format
  return `${formattedHour}:${formattedMinute} ${period}`;
};

interface RescheduleRequestProps {
  open?: boolean;
  influencer: InfluencerBooking;
  callback?: () => void;
}

const FormDateSuggestions: React.FC<{
  form: any;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = (props) => {
  const { form, loading, onSubmit, onCancel } = props;

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={{ preferredDates: [{}] }}
    >
      <Form.List name="preferredDates">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{ display: "flex", gap: "8px" }}>
                <Form.Item
                  {...restField}
                  name={[name, "date"]}
                  rules={[{ required: true, message: "Please select a date" }]}
                >
                  <Input
                    type="date"
                    min={
                      new Date(Date.now() + 86400000)
                        .toISOString()
                        .split("T")[0]
                    }
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "hour"]}
                  rules={[{ required: true, message: "Please select a time" }]}
                >
                  <Input type="time" />
                </Form.Item>
                <Button type="link" onClick={() => remove(name)}>
                  Remove
                </Button>
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                Add Date & Time
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Suggest
        </Button>
        <Button
          style={{ justifySelf: "flex-end" }}
          onClick={onCancel}
          loading={loading}
        >
          Back
        </Button>
      </div>
    </Form>
  );
};

// const FormMessage: React.FC<{
//   form: any;
//   loading: boolean;
//   onSubmit: () => void;
//   onCancel: () => void;
// }> = (props) => {
//   const { form, loading, onSubmit, onCancel } = props;

//   return (
//     <Form form={form} onFinish={onSubmit}>
//       <Form.Item
//         name="fieldMessage"
//         rules={[
//           {
//             required: true,
//             message: "Provide a @username or a URL to your account.",
//           },
//         ]}
//       >
//         <Input.TextArea placeholder="Message" />
//       </Form.Item>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "16px",
//         }}
//       >
//         <Button type="primary" htmlType="submit" loading={loading}>
//           Send
//         </Button>
//         <Button
//           style={{ justifySelf: "flex-end" }}
//           onClick={onCancel}
//           loading={loading}
//         >
//           Back
//         </Button>
//       </div>
//     </Form>
//   );
// };

const RescheduleRequest: React.FC<RescheduleRequestProps> = (props) => {
  const { influencer, open = false } = props;
  const { bookingId, collabId } = influencer;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [showReschedule, setShowReschedule] = useState<boolean>(open);
  const [dateSelect, setDateSelect] = useState<boolean>(false);
  // const [messageSelected, setMessageSelected] = useState<boolean>(false);

  const title = dateSelect
    ? "Suggest another date"
    : // : messageSelected
      // ? "Send a message"
      "Reschedule";

  const onFinishFormDateSuggestions = async () => {
    const { preferredDates } = form.getFieldsValue();
    setLoading(true);
    try {
      await rescheduleApplicationSuggestedDates({
        bookingId: bookingId,
        collabId: collabId,
        preferredDates: preferredDates.map(
          (date: { date: string; hour: string }) => {
            return {
              date: date.date,
              hour: convert24HourToAmPmFormat(date.hour),
            };
          }
        ),
      });
      // setDateSelect(false);
      message.success("Application rescheduled successfully");
      // setShowReschedule(false);
      navigate(".", { replace: true });
      form.resetFields();
    } catch (error) {
      message.error("Error rescheduling application");
      console.log(error);
    }

    setLoading(false);
  };

  // const onFinishFormMessage = async () => {
  //   const { fieldMessage } = form.getFieldsValue();
  //   setLoading(true);
  //   try {
  //     await rescheduleBusinessApplication({
  //       bookingId: bookingId,
  //       collabId: collabId,
  //       additionalMessage: fieldMessage,
  //     });
  //     setMessageSelected(false);
  //     message.success("Application rescheduled successfully");
  //     setShowReschedule(false);
  //     navigate(".", { replace: true });
  //   } catch (error) {
  //     message.error("Error rescheduling application");
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  return (
    <div>
      <span
        onClick={() => setShowReschedule(true)}
        style={{ cursor: "pointer" }}
      >
        <RescheduleIcon /> Reschedule
      </span>
      <Modal
        open={showReschedule}
        title={title}
        onCancel={() => setShowReschedule(false)}
        footer={null}
      >
        {influencer.tabStatus === "accepted" && (
          <Typography.Title level={5} style={{ color: "red" }}>
            Attention: The original booking will be canceled. The foodie must
            select one of the suggested times to secure a new booking.
          </Typography.Title>
        )}
        <Typography.Text
          style={{ color: "#999999" }}
          // hidden={dateSelect || messageSelected}
        >
          To make rescheduling easier, please share a few date options for the
          foodie to choose from.
          {/* or send them a message */}
        </Typography.Text>
        <FormDateSuggestions
          form={form}
          loading={loading}
          onSubmit={onFinishFormDateSuggestions}
          onCancel={() => setDateSelect(false)}
        />

        {/* {messageSelected && (
          <FormMessage
            form={form}
            loading={loading}
            onSubmit={onFinishFormMessage}
            onCancel={() => setMessageSelected(false)}
          />
        )} */}

        {/* <Input.TextArea
          style={{ display: "none" }}
          placeholder="Message"
          value={additionalMessage}
          onChange={(evt) => setAdditionalMessage(evt.target.value)}
        /> */}
        {/* {!dateSelect && !messageSelected && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Button
              style={{ justifySelf: "flex-end" }}
              onClick={() => setDateSelect(true)}
              type="primary"
              loading={loading}
            >
              Suggest another date
            </Button>
            <Button
              style={{ justifySelf: "flex-end" }}
              onClick={() => setMessageSelected(true)}
              loading={loading}
            >
              Send a message
            </Button>
          </div>
        )} */}
      </Modal>
    </div>
  );
};

export default RescheduleRequest;
