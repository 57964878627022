import { autenticado } from "helpers/auth-functions";
import { Navigate } from "react-router-dom";

const AuthenticationGuard: React.FC<any> = ({ children }) => {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const businessId = searchParams.get("businessId");
  // if (businessId && businessId !== null) {
  //   localStorage.setItem("businessId", businessId);
  //   searchParams.delete("businessId");
  //   window.history.replaceState({}, "", location.pathname);
  // }

  const isAuthenticated = autenticado();
  if (!isAuthenticated) {
    // Usuário não está autenticado, redirecionar para login
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthenticationGuard;
