import { Button, Form, Input, Modal, Typography } from "antd";
import { useState } from "react";
import { ReactComponent as CheckIcon } from "assets/images/round-check-icon.svg";
import { postSocialMediaInstructions } from "services/SauceService";

interface ModalInstructionsProps {
  open: boolean;
  onCancel: () => void;
}

const ModalInstructions: React.FC<ModalInstructionsProps> = (props) => {
  const { open, onCancel } = props;
  const [form] = Form.useForm();
  const [success, setSuccess] = useState<boolean>(false);

  const onFinishForm = async () => {
    const { email } = form.getFieldsValue();
    try {
      await postSocialMediaInstructions({ email });
    } catch (error) {
      console.log(error);
    }

    setSuccess(true);
  };

  return (
    <Modal
      open={open}
      title={!success && "Send instructions"}
      onCancel={onCancel}
      footer={null}
    >
      {success ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckIcon />
          <Typography.Text style={{ margin: "16px 0 32px 0" }}>
            Instructions have been successfully sent, please check your email
          </Typography.Text>
          <Button type="primary" style={{ width: "100%" }} onClick={onCancel}>
            Close
          </Button>
        </div>
      ) : (
        <>
          <Typography.Text>
            Provide the email to send instructions and select the social
            networks you want to connect
          </Typography.Text>
          <Form form={form} onFinish={onFinishForm}>
            <Form.Item
              rules={[
                { type: "email", message: "The input is not valid E-mail!" },
                { required: true, message: "Please input your email!" },
              ]}
              name="email"
            >
              <Input placeholder="Email" style={{ margin: "16px 0" }} />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: 32 }}
            onClick={() => form.submit()}
          >
            Send instructions
          </Button>
        </>
      )}
    </Modal>
  );
};

export default ModalInstructions;
