import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import VerificationInput from "react-verification-input";
import { postVerifyCode, sendVerificationCode } from "services/SauceService";

interface Props {
  setSubmitBlocked: (value: boolean) => void;
}

const Expectations: React.FC<Props> = (props) => {
  const { setSubmitBlocked } = props;
  const form = Form.useFormInstance();
  const [timerToSendAgain, setTimerToSendAgain] = useState<number>();

  const startTimer = useCallback(() => {
    setTimerToSendAgain(60);
    const interval = setInterval(() => {
      setTimerToSendAgain((prev) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev === undefined) {
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const onClickSendVerificationCode = async () => {
    const phone = form.getFieldValue("phone");
    startTimer();

    const params = {
      type: "phone",
      contact: phone,
    };
    try {
      await sendVerificationCode(params);
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || "Error sending verification code";

      message.error(errorMessage);
    }
  };

  const onClickVerifyCode = async () => {
    const { verificationCode, phone } = form.getFieldsValue(true);
    const params = {
      type: "phone",
      contact: phone,
      code: verificationCode,
    };
    try {
      await postVerifyCode(params);
      setSubmitBlocked(false);
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || "Error verifying code";

      message.error(errorMessage);
    }
  };

  useEffect(() => {
    setSubmitBlocked(true);
    return () => setSubmitBlocked(false);
  }, [setSubmitBlocked]);

  return (
    <div className="last-step">
      <Typography.Title level={2}>Last step before we launch!</Typography.Title>
      <Typography.Title level={5} style={{ marginBottom: 76 }}>
        Let's verify the contact info you provided
      </Typography.Title>
      <Typography.Text strong>Send the verification code to:</Typography.Text>
      <Row gutter={12}>
        <Col>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
            noStyle
          >
            <Input style={{ width: isMobile ? 250 : 300 }} />
          </Form.Item>
        </Col>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{ height: 60 }}
            onClick={onClickSendVerificationCode}
            disabled={timerToSendAgain !== undefined && timerToSendAgain > 0}
          >
            Send
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24, height: 22 }}>
        {(timerToSendAgain || -1) > 0 && (
          <span className="timer-span">
            Resend the code in: 0:{timerToSendAgain}
          </span>
        )}
      </Row>
      <Typography.Text strong>Verification code:</Typography.Text>
      <Row gutter={12}>
        <Col>
          <Form.Item
            name="verificationCode"
            rules={[{ required: true, message: "Please input your phone!" }]}
            noStyle
          >
            <VerificationInput
              validChars="0-9"
              inputProps={{
                inputMode: "numeric",
                autoComplete: "one-time-code",
              }}
              classNames={{
                container: isMobile ? "verification-input-mobile" : "",
              }}
            />
          </Form.Item>
        </Col>
        <Col style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <Button style={{ height: 60 }} onClick={onClickVerifyCode}>
            Verify
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Expectations;
