import {
  CloseOutlined,
  InstagramOutlined,
  PlusCircleOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { Form, Input, Modal, Select, Switch, Typography } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { getPlacesByName, postAddVenue } from "services/SauceService";
import venueWorkingHours from "./weekHoursDefault.json";
import WeekHoursField from "./WeekHoursField";
import { useSelector } from "react-redux";
import { useWatch } from "antd/es/form/Form";

interface ModalVenueProps {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const ModalVenue: React.FC<ModalVenueProps> = (props) => {
  const { open, onCancel, onSave } = props;
  const [form] = Form.useForm();
  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [places, setPlaces] = useState<any[]>([]);
  const { business } = useSelector((state: any) => state.business);
  const googlePlaceId = useWatch("googlePlaceId", form);

  const getLocation = useCallback(async (locationName: string) => {
    setLoadingLocation(true);
    const response: any = await getPlacesByName({ name: locationName });
    setPlaces(
      response.map((place: any) => ({
        value: place.place_id,
        label: place.name,
        ...place,
      }))
    );
    setLoadingLocation(false);
  }, []);

  const handleSearch = (newValue: string) => {
    if (newValue) getLocation(newValue);
  };

  const handleChange = (_: string, option: any) => {
    form.setFieldValue("addressDisplay", option.formatted_address);
    form.setFieldValue("name", option.name);
  };

  const onFinishForm = async () => {
    setLoading(true);
    const values = form.getFieldsValue(true);
    const params = {
      ...values,
      businessId: business._id,
    };
    const response = await postAddVenue(params);
    if (response) {
      onSave();
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onFinishForm}
      title="Add another location"
      cancelButtonProps={{ type: "link" }}
      okText="Add location"
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          pocs: [
            {
              name: "",
              email: "",
              notifyEmail: false,
              phone: "",
            },
          ],
          venueWorkingHours,
        }}
      >
        <Form.Item label="Find location" name="googlePlaceId">
          <Select
            placeholder="Typing the name and address"
            showSearch
            filterOption={false}
            onSearch={debounce(handleSearch, 500)}
            onSelect={handleChange}
            options={places}
            loading={loadingLocation}
            allowClear
            optionRender={(option: any) => {
              return (
                <>
                  <div style={{ padding: "8px", cursor: "pointer" }}>
                    {`${option.data.name} - ${option.data.formatted_address}`}
                  </div>
                </>
              );
            }}
          />
        </Form.Item>

        <Form.Item label="Name" name="name" hidden={!googlePlaceId} noStyle>
          <Input placeholder="Name of the location" />
        </Form.Item>

        <div
          style={{
            backgroundColor: "#F9F5ED",
            marginBottom: 24,
            height: 40,
            marginTop: -4,
            borderRadius: "0 0 6px 6px",
            display: googlePlaceId ? "flex" : "none",
            alignItems: "center",
            border: "1px solid #E6E6E6",
            padding: "8px 16px",
            gap: 8,
          }}
        >
          <Form.Item name="ghostKitchen" noStyle>
            <Switch />
          </Form.Item>
          Ghost kitchen
        </div>
        <Typography.Text style={{ paddingBottom: 8, display: "block" }}>
          Point of contact
        </Typography.Text>
        <Form.List name="pocs">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: 32,
              }}
            >
              {fields.map((field, index) => (
                <div key={`poc_${field.name}`}>
                  {index > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 8,
                      }}
                    >
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </div>
                  )}
                  <Form.Item name={[field.name, "name"]}>
                    <Input placeholder="First name" />
                  </Form.Item>
                  <Form.Item name={[field.name, "email"]} noStyle>
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                  <div
                    style={{
                      backgroundColor: "#F9F5ED",
                      marginBottom: 24,
                      height: 40,
                      marginTop: -4,
                      borderRadius: "0 0 6px 6px",
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #E6E6E6",
                      padding: "8px 16px",
                      gap: 8,
                    }}
                  >
                    <Form.Item
                      name={[field.name, "notifyEmail"]}
                      noStyle
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    Send login information to email
                  </div>
                  <Form.Item name={[field.name, "phone"]} noStyle>
                    <Input placeholder="Phone number" />
                  </Form.Item>
                </div>
              ))}

              <span
                style={{ color: "#1D2023", cursor: "pointer" }}
                onClick={() => add()}
              >
                <PlusCircleOutlined style={{ marginRight: 4 }} />
                Add another POC
              </span>
            </div>
          )}
        </Form.List>
        <Form.Item label="Links to social media" name="instagramUrl">
          <Input placeholder="Instagram" prefix={<InstagramOutlined />} />
        </Form.Item>
        <Form.Item name="tiktokUrl">
          <Input placeholder="TikTok" prefix={<TikTokOutlined />} />
        </Form.Item>
        <Typography.Text style={{ paddingBottom: 8, display: "block" }}>
          Working Hours
        </Typography.Text>
        <WeekHoursField name="venueWorkingHours" form={form} />
      </Form>
    </Modal>
  );
};

export default ModalVenue;
