import {
  CloseCircleOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Form, Popover } from "antd";
import { useEffect, useState } from "react";
import "./FieldFilter.scss";

const FieldFilter: React.FC<any> = (props) => {
  const { children, name, label, formLabel, valueFunction, transformValue } =
    props;
  const [value, setValue] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const form = Form.useFormInstance();
  const fieldValue = Form.useWatch(name, form);

  const onClickApply = () => {
    const transformedValue = transformValue
      ? transformValue(fieldValue)
      : fieldValue;
    setValue(transformedValue);
    form.setFieldValue(name, transformedValue);
    form.submit();
    setOpen(false);
  };

  const onClickClear = () => {
    form.resetFields([name]);
    setValue(undefined);
    form.submit();
  };

  const handleOpenChange = (visible: boolean) => {
    setOpen(visible);
    if (!visible && value !== fieldValue) {
      const transformedValue = transformValue
        ? transformValue(fieldValue)
        : fieldValue;
      setValue(transformedValue);
      form.setFieldValue(name, transformedValue);
      form.submit();
    }
  };

  useEffect(() => {
    if (!fieldValue) {
      setValue(undefined);
    }
  }, [fieldValue]);

  return (
    <Popover
      arrow={false}
      placement="bottomLeft"
      trigger={"click"}
      open={open}
      content={() => (
        <div className="field-filter content">
          <span>{formLabel}</span>
          <Form.Item name={name} labelAlign="right">
            {children}
          </Form.Item>
          <Button type="primary" htmlType="button" onClick={onClickApply}>
            Apply
          </Button>
        </div>
      )}
      onOpenChange={handleOpenChange}
    >
      <div className={`field-filter`}>
        <div
          className={`container ${open ? "open" : ""} ${
            value ? "has-value" : ""
          }`}
        >
          {value ? valueFunction(value) : label}
          {open ? <UpOutlined /> : <DownOutlined />}
        </div>
        {value && (
          <button
            onClick={onClickClear}
            className="button-clear"
            onMouseOver={(evt) => evt.stopPropagation()}
          >
            <CloseCircleOutlined />
          </button>
        )}
      </div>
    </Popover>
  );
};

export default FieldFilter;
