import {
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Popover,
  Row,
} from "antd";
import { ReactComponent as CollabsIcon } from "assets/images/collabs-icon.svg";
import { ReactComponent as HomeIcon } from "assets/images/home-icon.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as TextLogo } from "assets/images/text-logo.svg";
import { ReactComponent as Profile } from "assets/images/profile-icon.svg";
import { ReactComponent as InfluencerIcon } from "assets/images/influencer-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/images/messages-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/images/settings-icon.svg";
import { ReactComponent as ReportIcon } from "assets/images/report-up-icon.svg";
import BrandPlaceholder from "assets/images/brand-placeholder.png";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { logout } from "helpers/auth-functions";
import ChangeBusinessModalControl from "components/businesses-modal/ChangeBusinessModalControl";
import { CollabsState, getPendingCollabsCount } from "redux-context/collabs";
import { useEffect, useState } from "react";
import { InfluencerType } from "enums/InfluencerType";
import { BusinessState } from "redux-context/business";
import { UserState } from "redux-context/user";
import { ChatState } from "redux-context/chat";
import "./Layout.scss";

const { Header, Content, Sider } = Layout;

const DefaultLayout: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectedKey, setSelectedKey] = useState("");
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const whiteLabel = false;

  const { loggedUser } = useSelector((state: UserState) => state.user);
  const isAdmin = loggedUser?.roles?.includes("admin");

  const { business, subscription } = useSelector(
    (state: BusinessState) => state.business
  );
  const { numbers } = useSelector((state: CollabsState) => state.collabs);
  const { totalUnreadCount } = useSelector((state: ChatState) => state.chat);

  const basicRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.basic
    )?.amountRemaining || 0;

  const premiumRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.premium
    )?.amountRemaining || 0;

  const dropdownItens: MenuProps["items"] = [
    {
      key: "business",
      label: <span style={{ fontWeight: 500 }}>{business?.businessName}</span>,
    },
    {
      key: "slots",
      label: (
        <>
          <div>
            <span style={{ display: "block", height: 20 }}>
              Slots available
            </span>
            <span style={{ fontWeight: 500 }}>
              {`${basicRemaining} Basic ∘ ${premiumRemaining} Premium`}
            </span>
          </div>
        </>
      ),
    },
    {
      key: "create-collab",
      label: (
        <Button type="primary" onClick={() => navigate("/collabs/new")}>
          Create Collab
        </Button>
      ),
    },
    {
      key: "change-business",
      label: (
        <Button type="link" style={{ textAlign: "right" }}>
          <ChangeBusinessModalControl />
        </Button>
      ),
    },
    {
      key: "logout",
      label: (
        <Button type="link" onClick={logout} style={{ textAlign: "right" }}>
          Logout
        </Button>
      ),
    },
  ];

  const memuItems = [
    {
      key: "home",
      icon: <HomeIcon />,
      label: <span className="label-strong">Home</span>,
      onClick: () => navigate(`/`),
    },
    {
      key: "collabs",
      icon: <CollabsIcon />,
      label: <span className="label-strong">Collabs</span>,

      onClick: () => navigate(`/collabs`),
    },
    {
      key: "influencers",
      icon: <InfluencerIcon />,
      label: (
        <Badge
          count={numbers.pending}
          offset={[25, 8]}
          color="#ffac39"
          style={{ color: "#1D2023", fontWeight: 700, padding: "0 8px" }}
        >
          <span
            style={{ color: isMobile ? "#1D2023" : "#1d2023e0" }}
            className="label-strong"
          >
            Influencers
          </span>
        </Badge>
      ),
      onClick: () => navigate(`/influencers`),
    },
    {
      key: "report",
      icon: <ReportIcon />,
      label: <span className="label-strong">Report</span>,
      onClick: () => navigate(`/report?businessId=${business?._id}`),
    },
    // ...(isAdmin
    //   ? [
    //       {
    //         key: "messenger",
    //         icon: <MessagesIcon />,
    //         label: (
    //           <Badge
    //             count={totalUnreadCount}
    //             offset={[25, 8]}
    //             color="#ffac39"
    //             style={{ color: "#1D2023", fontWeight: 700, padding: "0 8px" }}
    //             className="text-strongest-small"
    //           >
    //             <span className="label-strong">Messenger</span>
    //           </Badge>
    //         ),
    //         onClick: () => navigate(`/messenger`),
    //       },
    //     ]
    //   : []),
    {
      key: "settings",
      icon: <SettingsIcon />,
      label: <span className="label-strong">Settings</span>,
      onClick: () => navigate(`/settings`),
    },
  ];

  const onClickLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const path = pathArray.length > 1 && pathArray[1] ? pathArray[1] : "home";
    setSelectedKey(path);
  }, [location.pathname]);

  if (isMobile)
    return (
      <Layout
        style={{ minHeight: "100vh", padding: isMobile ? 0 : "40px 80px" }}
      >
        <div className={`menu content-menu ${collapsed ? "collapsed" : ""}`}>
          <div className="menu-top">
            <div className="item">
              {collapsed ? (
                <MenuOutlined onClick={() => setCollapsed(false)} />
              ) : (
                <CloseOutlined
                  className="item"
                  style={{ width: 32 }}
                  onClick={() => setCollapsed(true)}
                />
              )}
            </div>
            <div className="item center">
              <Logo style={{ width: 80 }} />
            </div>

            <div className="item last">
              <Dropdown
                menu={{ items: dropdownItens }}
                placement="bottomLeft"
                rootClassName="menu-principal"
              >
                <Profile style={{ width: 20, height: 20 }} />
              </Dropdown>
            </div>
          </div>
          <div className="vertical-content">
            <Menu
              hidden={collapsed}
              selectedKeys={[selectedKey]}
              mode="inline"
              onClick={(evt) => {
                setSelectedKey(evt.key);
                setCollapsed(true);
              }}
              onChange={(evt) => {
                console.log(evt, "Event");
                dispatch(getPendingCollabsCount());
              }}
              items={memuItems}
            />
          </div>
        </div>
        <Layout>
          <Header>
            <Menu
              mode="horizontal"
              selectedKeys={[selectedKey]}
              onClick={(evt) => setSelectedKey(evt.key)}
              onChange={(evt) => {
                console.log(evt, "Event");
                dispatch(getPendingCollabsCount());
              }}
              items={memuItems}
            />
          </Header>
          <Content style={{ margin: isMobile ? "0 16px" : "0 0 0 64px" }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    );

  return (
    <Layout style={{ minHeight: "100vh", padding: isMobile ? 0 : "40px 80px" }}>
      <Sider
        collapsed={isMobile}
        style={{
          borderInlineEnd: isMobile ? "1px solid rgba(5, 5, 5, 0.06)" : "",
        }}
      >
        <div
          style={{
            height: 84,
            display: "flex",
            alignItems: "center",
            paddingLeft: isMobile ? 32 : 24,
          }}
        >
          {whiteLabel ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={BrandPlaceholder}
                alt="Brand"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  marginBottom: 8,
                }}
              />
              <span className="text-stronger-extra-small">
                powered by <TextLogo />
              </span>
            </div>
          ) : (
            <Logo />
          )}
        </div>
        <Menu
          selectedKeys={[selectedKey]}
          mode="inline"
          onClick={(evt) => setSelectedKey(evt.key)}
          onChange={(evt) => {
            dispatch(getPendingCollabsCount());
          }}
          items={memuItems}
        />
      </Sider>
      <Layout>
        <Header>
          <Row justify="end" style={{ alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <div>
                <span
                  style={{ display: "block", height: 20 }}
                  className="text-regular-small"
                >
                  Slots available
                </span>
                <span className="text-strong">
                  {`${basicRemaining} Basic ∘ ${premiumRemaining} Premium`}
                </span>
              </div>
              <Button
                type="primary"
                onClick={() => navigate("/collabs/new")}
                className="label-strong"
              >
                Create collab
              </Button>
              <Popover
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "right",
                    }}
                  >
                    <Button type="link" style={{ textAlign: "right" }}>
                      <ChangeBusinessModalControl />
                    </Button>
                    <Button
                      type="link"
                      onClick={onClickLogout}
                      style={{ textAlign: "right" }}
                    >
                      Logout
                    </Button>
                  </div>
                }
              >
                <span
                  style={{ display: "flex", alignItems: "center", gap: 12 }}
                  className="label-strong"
                >
                  <Profile style={{ width: 24, height: 24 }} />{" "}
                  {business?.businessName} <DownOutlined />
                </span>
              </Popover>
            </div>
          </Row>
        </Header>
        <Content style={{ margin: isMobile ? "0 16px" : "0 0 0 64px" }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
