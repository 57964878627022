import { Row, Typography } from "antd";
import ErrorBG from "assets/images/error-bg.png";

const Error: React.FC = () => {
  // const handleRetry = () => {
  //   // Função para tentar novamente ou redirecionar
  //   window.location.reload();
  // };

  return (
    <div style={{ textAlign: "center" }}>
      <Typography.Title level={3}>
        Sorry we encountered an enforceable error.{" "}
      </Typography.Title>
      <Row justify="center">
        <img
          src={ErrorBG}
          alt="Error"
          style={{ maxWidth: "100%", height: "auto", margin: "80px 0" }}
        />
      </Row>
      <Typography.Text style={{ fontSize: 16 }}>
        Our engineers are already notified and working on the fix.
      </Typography.Text>
      {/* <Button type="primary" onClick={handleRetry}>
        Try Again
      </Button> */}
    </div>
  );
};

export default Error;
